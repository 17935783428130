import React from "react";

const ManagementIcon = () => {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="45" viewBox="0 0 45 45" fill="none">
            <path d="M22.5224 24.8912C18.739 24.8912 15.6433 21.7955 15.6433 18.0121V15.545C15.6433 11.7615 18.739 8.66583 22.5224 8.66583C26.3059 8.66583 29.4016 11.7615 29.4016 15.545V18.0121C29.4016 21.796 26.3059 24.8912 22.5224 24.8912Z" fill="white"/>
            <path d="M26.396 25.8365C25.2324 26.439 23.9171 26.7859 22.5225 26.7859C21.1278 26.7859 19.8125 26.439 18.6489 25.8365C13.4481 26.6129 9.42529 31.126 9.42529 36.5336V43.6492C9.42529 44.3005 9.95832 44.833 10.6091 44.833H34.4348C35.0861 44.833 35.6186 44.3 35.6186 43.6492V36.5336C35.6196 31.126 31.5968 26.6129 26.396 25.8365Z" fill="white"/>
            <path d="M35.4674 17.6666C34.3038 18.2691 32.9885 18.6159 31.5938 18.6159C31.5918 18.6159 31.5904 18.6159 31.5884 18.6159C31.4436 20.7894 30.5371 22.7545 29.1277 24.2467C34.2289 26.0601 37.8365 30.9707 37.8365 36.5335V36.6631H43.5072C44.1584 36.6631 44.6915 36.13 44.6915 35.4788V28.3637C44.691 22.9555 40.6681 18.4425 35.4674 17.6666Z" fill="white"/>
            <path d="M31.5939 0.49646C28.0263 0.49646 25.0729 3.24979 24.7473 6.73665C28.6884 7.73325 31.6181 11.2989 31.6181 15.5454V16.7204C35.3902 16.7071 38.4726 13.6178 38.4726 9.8427V7.3756C38.4731 3.59217 35.3774 0.49646 31.5939 0.49646Z" fill="white"/>
            <path d="M7.209 36.5335C7.209 30.9707 10.8161 26.0596 15.9173 24.2467C14.5079 22.7545 13.6009 20.7889 13.4566 18.6159C13.4546 18.6159 13.4531 18.6159 13.4512 18.6159C12.0565 18.6159 10.7417 18.2691 9.57758 17.6666C4.37685 18.4425 0.354004 22.9555 0.354004 28.3637V35.4793C0.354004 36.1305 0.887034 36.6636 1.5383 36.6636H7.209V36.5335Z" fill="white"/>
            <path d="M13.4265 16.7199V15.5449C13.4265 11.2984 16.3557 7.73276 20.2973 6.73616C19.9721 3.24979 17.0188 0.49646 13.4512 0.49646C9.66773 0.49646 6.57202 3.59217 6.57202 7.3756V9.8427C6.57202 13.6178 9.65443 16.7066 13.4265 16.7199Z" fill="white"/>
        </svg>
    )
};

export default ManagementIcon;
import React from "react";

const AdminPatients = () => {
    return (
        <div className="admin-patients">
            <h1>Patients</h1>
        </div>
    )
};

export default AdminPatients;
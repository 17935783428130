import React from "react";

const AdminLearnMore = () => {
    return (
        <>
            <h1>Learn More</h1>
        </>
    )
};

export default AdminLearnMore;
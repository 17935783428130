import React from "react";

const DepartmentIcon = () => {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width="56" height="54" viewBox="0 0 56 54" fill="none">
            <path d="M32.2697 7.14693H29.0659V3.94934H27.214V7.14693H24.0103V8.99881H27.214V12.2026H29.0659V8.99881H32.2697V7.14693Z" fill="#D34A4A"/>
            <path d="M52.2847 51.5088V10.3074H36.8054V0.0455322H19.4743V10.3074H3.99497V51.5082H0.361572V53.3601H24.0126H32.2665H55.9181V51.5082L52.2847 51.5088ZM50.4328 12.1593V51.5082H36.8054V12.1593H50.4328ZM5.84685 12.1593H19.4743V51.5082H5.84685V12.1593ZM25.8645 51.5088V39.6117C25.8645 38.3573 26.8849 37.3363 28.1398 37.3363C29.3948 37.3363 30.4152 38.3567 30.4152 39.6117V51.5082L25.8645 51.5088ZM28.1398 35.4851C25.8639 35.4851 24.0126 37.3363 24.0126 39.6123V51.5088H21.3255V10.3074V1.89742H34.9529V10.3074V51.5082H32.2658V39.6117C32.2671 37.3363 30.4158 35.4851 28.1398 35.4851Z" fill="#D34A4A"/>
            <path d="M32.2672 14.4323H24.0127V22.6867H32.2665L32.2672 14.4323ZM30.4153 20.8342H25.8652V16.2835H30.4153V20.8342Z" fill="#D34A4A"/>
            <path d="M32.2672 24.9583H24.0127V33.2128H32.2665L32.2672 24.9583ZM30.4153 31.3609H25.8652V26.8102H30.4153V31.3609Z" fill="#D34A4A"/>
            <path d="M47.7463 14.4323H39.4924V22.6867H47.7463V14.4323ZM45.8944 20.8342H41.3443V16.2835H45.8944V20.8342Z" fill="#D34A4A"/>
            <path d="M47.7463 24.9583H39.4924V33.2128H47.7463V24.9583ZM45.8944 31.3609H41.3443V26.8102H45.8944V31.3609Z" fill="#D34A4A"/>
            <path d="M47.7463 35.4852H39.4924V43.7396H47.7463V35.4852ZM45.8944 41.8877H41.3443V37.3371H45.8944V41.8877Z" fill="#D34A4A"/>
            <path d="M8.5332 14.4323V22.6867H16.7877V14.4323H8.5332ZM14.9358 20.8342H10.3851V16.2835H14.9358V20.8342Z" fill="#D34A4A"/>
            <path d="M8.5332 33.2128H16.7877V24.9583H8.5332V33.2128ZM10.3851 26.8102H14.9358V31.3609H10.3851V26.8102Z" fill="#D34A4A"/>
            <path d="M8.5332 43.7396H16.7877V35.4852H8.5332V43.7396ZM10.3851 37.3371H14.9358V41.8877H10.3851V37.3371Z" fill="#D34A4A"/>
        </svg>
    )

};

export default DepartmentIcon;
import React from "react";

const AdminSettings = () => {
    return (
        <div className="admin-settings">
            <h1>Settings</h1>
        </div>
    )
};

export default AdminSettings;
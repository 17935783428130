import React from "react";

const HospitalUnitIcon = () => {
    return ( 
        <svg xmlns="http://www.w3.org/2000/svg" width="57" height="47" viewBox="0 0 57 47" fill="none">
            <path d="M28.2445 22.3088C27.7328 22.3088 27.3186 21.8946 27.3186 21.3829V12.9759C27.3186 12.4642 27.7328 12.05 28.2445 12.05C28.7563 12.05 29.1705 12.4642 29.1705 12.9759V21.3829C29.1705 21.8946 28.7557 22.3088 28.2445 22.3088Z" fill="#D34A4A"/>
            <path d="M32.4476 18.1057H24.0407C23.529 18.1057 23.1147 17.6915 23.1147 17.1798C23.1147 16.6681 23.529 16.2538 24.0407 16.2538H32.4476C32.9587 16.2538 33.3736 16.6681 33.3736 17.1798C33.3736 17.6915 32.9587 18.1057 32.4476 18.1057Z" fill="#D34A4A"/>
            <path d="M55.508 26.7713L45.2164 21.5878V14.271H49.7017C50.1239 14.271 50.4924 13.9852 50.598 13.5766C50.7036 13.1679 50.5196 12.7395 50.1498 12.5346L28.6927 0.659078C28.4137 0.504754 28.0748 0.504754 27.7957 0.659078L6.33857 12.5346C5.96943 12.7389 5.78486 13.1673 5.89042 13.5766C5.99598 13.9852 6.3645 14.271 6.78673 14.271H11.5455V21.4446L0.969962 26.7713C0.65761 26.9287 0.460693 27.2484 0.460693 27.5984V45.1636C0.460693 45.6747 0.874898 46.0895 1.38664 46.0895H12.1874C12.2362 46.0895 12.2831 46.0827 12.3294 46.0753C12.3763 46.0827 12.4232 46.0895 12.4714 46.0895H24.0401H32.4471H44.2899H55.0913C55.6024 46.0895 56.0172 45.6747 56.0172 45.1636V27.5984C56.0172 27.2484 55.8203 26.9287 55.508 26.7713ZM11.2615 44.2376H2.31258V28.1682L11.2615 23.6613V44.2376ZM12.472 12.4192H10.3726L28.2445 2.52825L46.1164 12.4192H44.2905C43.7794 12.4192 43.3645 12.8334 43.3645 13.3451V22.1588V44.2376H33.3736V31.3201C33.3736 30.809 32.9588 30.3942 32.4477 30.3942H24.0407C23.529 30.3942 23.1148 30.809 23.1148 31.3201V44.2376H13.398V13.3451C13.398 12.8334 12.9838 12.4192 12.472 12.4192ZM24.9667 44.2376V32.2461H31.5217V44.2376H24.9667ZM54.1653 44.2376H45.2164V23.662L54.1653 28.1682V44.2376Z" fill="#D34A4A"/>
        </svg>
    )
};

export default HospitalUnitIcon;
import React from "react";

const HistoryIcons = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="45" height="44" viewBox="0 0 45 44" fill="none">
            <path d="M44.1567 14.5804H0.809235C0.537328 14.5804 0.31665 14.3597 0.31665 14.0878V9.50575C0.31665 9.30083 0.443737 9.1171 0.635353 9.04469L22.3091 0.873202C22.4209 0.83084 22.545 0.83084 22.6563 0.873202L44.3301 9.04469C44.5222 9.1166 44.6488 9.30083 44.6488 9.50575V14.0878C44.6493 14.3597 44.4291 14.5804 44.1567 14.5804ZM1.30182 13.5952H43.6641V9.84662L22.483 1.86034L1.30182 9.84612V13.5952Z" fill="#D34A4A"/>
            <path d="M44.1567 43.836H0.809235C0.537328 43.836 0.31665 43.6158 0.31665 43.3434V36.8698C0.31665 36.5974 0.537328 36.3773 0.809235 36.3773H44.1567C44.4291 36.3773 44.6493 36.5974 44.6493 36.8698V43.3434C44.6493 43.6153 44.4291 43.836 44.1567 43.836ZM1.30182 42.8508H43.6641V37.3624H1.30182V42.8508Z" fill="#D34A4A"/>
            <path d="M27.0507 20.3894H17.9152C17.6433 20.3894 17.4226 20.1688 17.4226 19.8968V14.0878C17.4226 13.8159 17.6433 13.5952 17.9152 13.5952C18.1871 13.5952 18.4078 13.8159 18.4078 14.0878V19.4048H26.5586V14.0878C26.5586 13.8159 26.7788 13.5952 27.0512 13.5952C27.3236 13.5952 27.5437 13.8159 27.5437 14.0878V19.8973C27.5432 20.1692 27.3231 20.3894 27.0507 20.3894Z" fill="#D34A4A"/>
            <path d="M12.6017 20.3894H3.46573C3.19382 20.3894 2.97314 20.1688 2.97314 19.8968V14.0878C2.97314 13.8159 3.19382 13.5952 3.46573 13.5952C3.73764 13.5952 3.95831 13.8159 3.95831 14.0878V19.4048H12.1091V14.0878C12.1091 13.8159 12.3298 13.5952 12.6017 13.5952C12.8736 13.5952 13.0943 13.8159 13.0943 14.0878V19.8973C13.0943 20.1692 12.8736 20.3894 12.6017 20.3894Z" fill="#D34A4A"/>
            <path d="M34.0219 31.5529C33.7495 31.5529 33.5293 31.3327 33.5293 31.0603V19.8969C33.5293 19.625 33.7495 19.4043 34.0219 19.4043C34.2943 19.4043 34.5145 19.625 34.5145 19.8969V31.0603C34.5145 31.3327 34.2943 31.5529 34.0219 31.5529Z" fill="#D34A4A"/>
            <path d="M39.8427 31.5529C39.5703 31.5529 39.3501 31.3327 39.3501 31.0603V19.8969C39.3501 19.625 39.5703 19.4043 39.8427 19.4043C40.1151 19.4043 40.3353 19.625 40.3353 19.8969V31.0603C40.3353 31.3327 40.1151 31.5529 39.8427 31.5529Z" fill="#D34A4A"/>
            <path d="M41.4996 20.3894H32.3642C32.0918 20.3894 31.8716 20.1688 31.8716 19.8968V14.0878C31.8716 13.8159 32.0918 13.5952 32.3642 13.5952C32.6366 13.5952 32.8568 13.8159 32.8568 14.0878V19.4048H41.0075V14.0878C41.0075 13.8159 41.2277 13.5952 41.5001 13.5952C41.7725 13.5952 41.9927 13.8159 41.9927 14.0878V19.8973C41.9922 20.1692 41.772 20.3894 41.4996 20.3894Z" fill="#D34A4A"/>
            <path d="M27.0507 37.3624C26.7783 37.3624 26.5581 37.1422 26.5581 36.8698V31.5529H18.4078V36.8698C18.4078 37.1422 18.1871 37.3624 17.9152 37.3624C17.6433 37.3624 17.4226 37.1422 17.4226 36.8698V31.0603C17.4226 30.7879 17.6433 30.5677 17.9152 30.5677H27.0512C27.3236 30.5677 27.5437 30.7879 27.5437 31.0603V36.8698C27.5432 37.1422 27.3231 37.3624 27.0507 37.3624Z" fill="#D34A4A"/>
            <path d="M41.4996 37.3624C41.2272 37.3624 41.0071 37.1422 41.0071 36.8698V31.5529H32.8568V36.8698C32.8568 37.1422 32.6366 37.3624 32.3642 37.3624C32.0918 37.3624 31.8716 37.1422 31.8716 36.8698V31.0603C31.8716 30.7879 32.0918 30.5677 32.3642 30.5677H41.5001C41.7725 30.5677 41.9927 30.7879 41.9927 31.0603V36.8698C41.9922 37.1422 41.772 37.3624 41.4996 37.3624Z" fill="#D34A4A"/>
            <path d="M19.5727 31.5529C19.3008 31.5529 19.0801 31.3327 19.0801 31.0603V19.8969C19.0801 19.625 19.3008 19.4043 19.5727 19.4043C19.8446 19.4043 20.0652 19.625 20.0652 19.8969V31.0603C20.0652 31.3327 19.8446 31.5529 19.5727 31.5529Z" fill="#D34A4A"/>
            <path d="M25.3932 31.5529C25.1208 31.5529 24.9006 31.3327 24.9006 31.0603V19.8969C24.9006 19.625 25.1208 19.4043 25.3932 19.4043C25.6656 19.4043 25.8858 19.625 25.8858 19.8969V31.0603C25.8858 31.3327 25.6656 31.5529 25.3932 31.5529Z" fill="#D34A4A"/>
            <path d="M5.1232 31.5529C4.85129 31.5529 4.63062 31.3327 4.63062 31.0603V19.8969C4.63062 19.625 4.85129 19.4043 5.1232 19.4043C5.39511 19.4043 5.61578 19.625 5.61578 19.8969V31.0603C5.61578 31.3327 5.3956 31.5529 5.1232 31.5529Z" fill="#D34A4A"/>
            <path d="M10.944 31.5529C10.6721 31.5529 10.4514 31.3327 10.4514 31.0603V19.8969C10.4514 19.625 10.6721 19.4043 10.944 19.4043C11.2159 19.4043 11.4366 19.625 11.4366 19.8969V31.0603C11.4366 31.3327 11.2159 31.5529 10.944 31.5529Z" fill="#D34A4A"/>
            <mask id="path-14-inside-1_978_3842" fill="white">
                <path d="M12.6017 37.3624C12.3298 37.3624 12.1091 37.1422 12.1091 36.8698V31.5529H3.95831V36.8698C3.95831 37.1422 3.73764 37.3624 3.46573 37.3624C3.19382 37.3624 2.97314 37.1422 2.97314 36.8698V31.0603C2.97314 30.7879 3.19382 30.5677 3.46573 30.5677H12.6017C12.8736 30.5677 13.0943 30.7879 13.0943 31.0603V36.8698C13.0943 37.1422 12.8736 37.3624 12.6017 37.3624Z"/>
            </mask>
            <path d="M12.6017 37.3624C12.3298 37.3624 12.1091 37.1422 12.1091 36.8698V31.5529H3.95831V36.8698C3.95831 37.1422 3.73764 37.3624 3.46573 37.3624C3.19382 37.3624 2.97314 37.1422 2.97314 36.8698V31.0603C2.97314 30.7879 3.19382 30.5677 3.46573 30.5677H12.6017C12.8736 30.5677 13.0943 30.7879 13.0943 31.0603V36.8698C13.0943 37.1422 12.8736 37.3624 12.6017 37.3624Z" fill="#D34A4A"/>
            <path d="M12.1091 31.5529H15.1091V28.5529H12.1091V31.5529ZM3.95831 31.5529V28.5529H0.958314V31.5529H3.95831ZM12.6017 34.3624C13.9847 34.3624 15.1091 35.4834 15.1091 36.8698H9.10911C9.10911 38.801 10.6749 40.3624 12.6017 40.3624V34.3624ZM15.1091 36.8698V31.5529H9.10911V36.8698H15.1091ZM12.1091 28.5529H3.95831V34.5529H12.1091V28.5529ZM0.958314 31.5529V36.8698H6.95831V31.5529H0.958314ZM0.958314 36.8698C0.958314 35.4834 2.08274 34.3624 3.46573 34.3624V40.3624C5.39254 40.3624 6.95831 38.801 6.95831 36.8698H0.958314ZM3.46573 34.3624C4.84872 34.3624 5.97314 35.4834 5.97314 36.8698H-0.0268555C-0.0268555 38.801 1.53892 40.3624 3.46573 40.3624V34.3624ZM5.97314 36.8698V31.0603H-0.0268555V36.8698H5.97314ZM5.97314 31.0603C5.97314 32.4467 4.84872 33.5677 3.46573 33.5677V27.5677C1.53892 27.5677 -0.0268555 29.1291 -0.0268555 31.0603H5.97314ZM3.46573 33.5677H12.6017V27.5677H3.46573V33.5677ZM12.6017 33.5677C11.2187 33.5677 10.0943 32.4467 10.0943 31.0603H16.0943C16.0943 29.1291 14.5285 27.5677 12.6017 27.5677V33.5677ZM10.0943 31.0603V36.8698H16.0943V31.0603H10.0943ZM10.0943 36.8698C10.0943 35.4834 11.2187 34.3624 12.6017 34.3624V40.3624C14.5285 40.3624 16.0943 38.801 16.0943 36.8698H10.0943Z" fill="#D34A4A" mask="url(#path-14-inside-1_978_3842)"/>
        </svg>
    )
};

export default HistoryIcons;